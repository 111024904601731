import { FC } from 'react';

import Button from 'components/Buttons/Button';
import { filtersUsedSelector } from 'redux/sidePanel';
import useAppSelector from 'hooks/redux/useAppSelector';

interface IProps {
  disable: boolean;
  sizeFilter: string;
  changeFilterHandler: (filterStr: string) => void;
}

const SidebarControls: FC<IProps> = ({
  disable,
  sizeFilter,
  changeFilterHandler,
}) => {
  const filtersUsed = useAppSelector(filtersUsedSelector);

  const filterButtonsList = filtersUsed.map((filterStr) => (
    <Button
      key={filterStr}
      className="sidebar-btn"
      isDisabled={disable}
      isActive={filterStr === sizeFilter}
      clickHandler={() => changeFilterHandler(filterStr)}
    >
      {filterStr.slice(2)}
    </Button>
  ));

  return (
    <div className="sidebar-btn-grid">
      <span className="sidebar-btn-category">Select Category:</span>
      {filterButtonsList}
    </div>
  );
};

export default SidebarControls;
