import React, { useCallback, useEffect, useState } from 'react';

import useSelectProductOptions from 'hooks/sidePanel/useSelectProductOptions';
import useSidebarSettings from 'hooks/sidePanel/useSidebarSettings';
import useAppSelector from 'hooks/redux/useAppSelector';
import useAppDispatch from 'hooks/redux/useAppDispatch';
import useAnimation from 'hooks/sidePanel/useAnimate';

import {
  setShowOptions,
  showOptionsSelector,
  isSelectedOrientationSelector,
  selectedProductSizeOptionSelector,
  isSelectedProductSizeOptionSelector,
} from 'redux/sidePanel';
import {
  activeImageObjectSelector,
  setIsShowGallery,
} from 'redux/gallery';

import { IProductOption, Void } from 'types/general';

import useProductSize from 'hooks/sidePanel/useProductSize';
import useResetEditor from 'hooks/editor/useResetEditor';
import SidebarSettings from '../SidebarSettings';
import Card from './Card';

interface ICardsProps {
  color?: string;
  isColor?: boolean;
  productOptions: IProductOption[];
  settingsTitle: string;
  selectedOption: IProductOption | null;
  showColorPickerHandler?: Void;
}

const Cards: React.FC<ICardsProps> = ({
  color,
  isColor,
  productOptions,
  settingsTitle,
  selectedOption,
  showColorPickerHandler,
}: ICardsProps) => {
  const [productCardId, setProductCardId] = useState<null | string>(
    null,
  );

  const activeImageObject = useAppSelector(activeImageObjectSelector);
  const showOptions = useAppSelector(showOptionsSelector);
  const isSelectedProductSizeOption = useAppSelector(
    isSelectedProductSizeOptionSelector,
  );
  const selectedProductSizeOption = useAppSelector(
    selectedProductSizeOptionSelector,
  );
  const isSelectedOrientation = useAppSelector(
    isSelectedOrientationSelector,
  );

  const dispatch = useAppDispatch();

  const [animations, isClicked, setIsClicked] = useAnimation();
  const selectProductOption = useSelectProductOptions();
  const [showSidebarSettings, showSidebarSettingsHandler] =
    useSidebarSettings();
  const applyProductSize = useProductSize();
  const resetEditor = useResetEditor();

  const isDisabled =
    !activeImageObject ||
    !isSelectedOrientation ||
    !selectedProductSizeOption;

  const updatedSelectProductOptionHandler = useCallback(
    (productOption: IProductOption) => {
      if (isDisabled) return;

      selectProductOption(productOption);

      if (
        productOption.productOptionId === 'COLOR' ||
        productOption.productOptionId === 'IMAGE'
      ) {
        const { size } = selectedProductSizeOption;
        if (!size) return;
        resetEditor();
        applyProductSize(size);
      }

      showSidebarSettingsHandler(false);

      if (showOptions) dispatch(setShowOptions(false));

      dispatch(setIsShowGallery(false));
    },
    [
      dispatch,
      isDisabled,
      showOptions,
      resetEditor,
      selectProductOption,
      applyProductSize,
      selectedProductSizeOption,
      showSidebarSettingsHandler,
    ],
  );

  const filteredOptionList = productOptions.filter(
    ({ productOptionId }: IProductOption) => {
      if (!productCardId || !isClicked) return true;

      return productCardId === productOptionId;
    },
  );

  const productOptionsList = filteredOptionList.map(
    (productOption: IProductOption) => (
      <Card
        isClicked={isClicked}
        animations={animations}
        setIsClicked={setIsClicked}
        setCardId={setProductCardId}
        productOption={productOption}
        key={productOption.productOptionId}
        disable={!isSelectedProductSizeOption}
        selectProductOptionHandler={updatedSelectProductOptionHandler}
      />
    ),
  );

  useEffect(() => {
    if (!selectedOption) return;

    showSidebarSettingsHandler(false);
  }, [selectedOption, showSidebarSettingsHandler]);

  return (
    <SidebarSettings
      color={color}
      isColor={isColor}
      title={settingsTitle}
      selectedOption={selectedOption}
      disable={!isSelectedProductSizeOption}
      showSidebarSettings={showSidebarSettings}
      showColorPickerHandler={showColorPickerHandler}
      showSidebarSettingsHandler={showSidebarSettingsHandler}
    >
      <div className="product-grid">{productOptionsList}</div>
    </SidebarSettings>
  );
};

Cards.defaultProps = {
  color: '',
  isColor: false,
  showColorPickerHandler: () => {},
};

export default Cards;
