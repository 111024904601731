export const ROUTES = {
  EDITOR: '/editor',
  CART: '/cart',
  CHECKOUT: '/checkout',
};

export const SIDE_PANEL_ROUTES = {
  METAL_PRINT: '/editor/metalPrint',
  ACRYLIC_PRINT: '/editor/acrylicPrint',
  CANVAS_WRAP: '/editor/canvasWrap',
};
