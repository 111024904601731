import { FC, useCallback, useContext } from 'react';
import cn from 'classnames';

import useDefineDeviceByWindowSize from 'hooks/useDefineDeviceByWindowSize';
import useEditLineItemMode from 'hooks/editor/useEditLineItemMode';
import useAppSelector from 'hooks/redux/useAppSelector';
import useUpload from 'hooks/useUpload';

import { Event } from 'types/general';
import {
  EditorWindowContext,
  SettingsContext,
  SidebarPopupContext,
} from 'context/contexts/editor/general';
import {
  activeImageObjectSelector,
  isGalleryPendingSelector,
  isShowGallerySelector,
} from 'redux/gallery';

import EditorControls from '../EditorControls';
import CanvasMobile from '../CanvasMobile';
import Gallery from '../Gallery';
import Upload from '../Upload';

const MainContent: FC = () => {
  const { showSidebarPopupHandler } = useContext(SidebarPopupContext);
  const { showEditorWindow } = useContext(EditorWindowContext);
  const { showSettingsHandler } = useContext(SettingsContext);

  const activeImageObject = useAppSelector(activeImageObjectSelector);
  const isGalleryPending = useAppSelector(isGalleryPendingSelector);
  const isShowGallery = useAppSelector(isShowGallerySelector);

  const [rootProps, inputProps, isDragActive] = useUpload();
  const { isMobile } = useDefineDeviceByWindowSize();

  // edit line item mode
  useEditLineItemMode();

  const hidePopupsHandler = useCallback(
    (e: Event<HTMLElement>) => {
      e.stopPropagation();
      showSettingsHandler(false);
      showSidebarPopupHandler(false);
    },
    [showSettingsHandler, showSidebarPopupHandler],
  );

  const isShowUpload = !isGalleryPending && !activeImageObject;
  const isShowEditor = !showEditorWindow && activeImageObject;

  return (
    <div className="content col">
      <div
        className={cn('content-container', {
          'is-active': !!activeImageObject,
          'is-drag-active': isDragActive,
          'is-loader': isGalleryPending,
        })}
      >
        {isGalleryPending && <div className="loader" />}
        {isShowUpload && (
          <Upload
            rootProps={rootProps}
            inputProps={inputProps}
            hidePopupsHandler={hidePopupsHandler}
          />
        )}
        {isShowEditor &&
          (!isMobile ? <EditorControls /> : <CanvasMobile />)}
      </div>
      {isShowGallery && (
        <Gallery hidePopupsHandler={hidePopupsHandler} />
      )}
    </div>
  );
};

export default MainContent;
