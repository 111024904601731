import { FC, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import sidePanelRoutesDependOnCategory from 'constants/sidePanel/sidePanelRoutesDependOnCategory';
import { ORIENTATION, SIZE } from 'constants/sidePanel/general';
import OPTION_STATES from 'constants/sidePanel/optionStates';
import {
  colorOptionCategory,
  edgeOptionsId,
} from 'constants/editor/canvasWraps';

import useSelectProductOptions from 'hooks/sidePanel/useSelectProductOptions';
import useDefineDeviceByWindowSize from 'hooks/useDefineDeviceByWindowSize';
import useAppDispatch from 'hooks/redux/useAppDispatch';
import useAppSelector from 'hooks/redux/useAppSelector';
import useResetEditor from 'hooks/editor/useResetEditor';
import useEditMode from 'hooks/editor/useEditMode';

import { activeImageObjectSelector } from 'redux/gallery';
import {
  colorSelector,
  setShowColorPicker,
  showColorPickerSelector,
} from 'redux/editor/colorPicker';
import {
  setIsResetCroppedElement,
  setIsResetCustomCorners,
} from 'redux/editor/editorReducer';
import {
  edgeOptionsSelector,
  isSelectedProductSizeOptionSelector,
  optionGroupsSelector,
  productCategoriesStatusSelector,
  productCategorySelector,
  selectedEdgeSelector,
} from 'redux/sidePanel';

import productOptionService from 'utils/sidePanel/ProductOptionService';

import SidebarSettings from '../SidebarSettings';
import CategoryCards from '../CategoryCards';
import SizeCards from '../SizeCards';
import Cards from '../Cards';

interface IProps {
  showProductCategories: boolean;
  showProductCategoriesHandler: (isShow: boolean) => void;
}

const SidebarBody: FC<IProps> = ({
  showProductCategories,
  showProductCategoriesHandler,
}) => {
  const activeImageObject = useAppSelector(activeImageObjectSelector);
  const showColorPicker = useAppSelector(showColorPickerSelector);
  const productCategory = useAppSelector(productCategorySelector);
  const selectedEdge = useAppSelector(selectedEdgeSelector);
  const optionGroups = useAppSelector(optionGroupsSelector);
  const edgeOptions = useAppSelector(edgeOptionsSelector);
  const color = useAppSelector(colorSelector);

  const productCategoriesStatus = useAppSelector(
    productCategoriesStatusSelector,
  );
  const isSelectedProductSize = useAppSelector(
    isSelectedProductSizeOptionSelector,
  );

  const dispatch = useAppDispatch();
  const history = useHistory();

  const { isMobile } = useDefineDeviceByWindowSize();
  const selectProductOption = useSelectProductOptions();
  const [isEditMode, setIsEditMode] = useEditMode();
  const resetEditor = useResetEditor();

  const [, COLOR] = edgeOptionsId;

  const isColorEdge = selectedEdge?.productOptionId === COLOR;

  const selectProductCategoryHandler = useCallback(
    (productCategoryType: string) => {
      if (isEditMode) {
        resetEditor();
        setIsEditMode(false);
      }

      dispatch(setIsResetCroppedElement(true));
      dispatch(setIsResetCustomCorners(true));

      showProductCategoriesHandler(false);

      const newRoute =
        sidePanelRoutesDependOnCategory[productCategoryType];

      history.push(newRoute);
    },
    [
      dispatch,
      history,
      isEditMode,
      resetEditor,
      setIsEditMode,
      showProductCategoriesHandler,
    ],
  );

  const showColorPickerHandler = useCallback(() => {
    dispatch(setShowColorPicker(!showColorPicker));
  }, [dispatch, showColorPicker]);

  const filteredOptionGroups =
    productOptionService.getFilteredProductOptionGroups(
      optionGroups,
      [ORIENTATION, SIZE],
    );

  const OptionGroupsList = filteredOptionGroups.map(
    ({ productOptions, productOptionGroupType, displayName }) => {
      const selectedOption =
        productOptionService.getSelectedProductOptionByType(
          optionGroups,
          productOptionGroupType,
        );

      const isShareColor =
        productOptionGroupType === colorOptionCategory && isColorEdge;

      return (
        <Cards
          isColor={isShareColor}
          settingsTitle={displayName}
          key={productOptionGroupType}
          productOptions={productOptions}
          selectedOption={selectedOption}
          color={isShareColor ? color : ''}
          showColorPickerHandler={showColorPickerHandler}
        />
      );
    },
  );

  // setup required options
  useEffect(() => {
    if (!edgeOptions || !isSelectedProductSize) return;

    const { productOptions } = edgeOptions;

    const isAnySelected = productOptions.some(({ optionsStates }) =>
      productOptionService.getOptionStateValue(
        optionsStates,
        OPTION_STATES.IS_SELECTED,
      ),
    );

    if (isAnySelected) return;

    const [imageWrap] = productOptions;

    selectProductOption(imageWrap);
  }, [edgeOptions, selectProductOption, isSelectedProductSize]);

  if (showProductCategories) {
    return (
      <div className="sidebar-body product-category ">
        <SidebarSettings
          title="wall displays"
          disable={!activeImageObject}
          selectedOption={productCategory}
        >
          <CategoryCards
            // temporary hide canvas wraps on mobile
            productCategoriesStatus={
              isMobile
                ? productCategoriesStatus.slice(0, 2)
                : productCategoriesStatus
            }
            selectProductCategoryHandler={
              selectProductCategoryHandler
            }
          />
        </SidebarSettings>
      </div>
    );
  }

  return (
    <div className="sidebar-body">
      <SizeCards />
      {OptionGroupsList}
    </div>
  );
};

export default SidebarBody;
