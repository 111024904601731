/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ICoordinates, ICropObject, ISize } from 'types/editor';

import { IEditorState, IPrevEditorState } from './types';

const defaultEditLineItemMode = {
  lineItemId: null,
  isEditLineItemMode: false,
  isSetupEditorView: false,
};

const initialState: IEditorState = {
  visualRotation: 0,
  croppedImageDpi: 0,
  cropObject: null,
  isEditMode: false,
  isResetCroppedElement: true,
  isResetCustomCorners: true,
  prevImageWrapperScale: null,
  originalCroppedImageSize: null,
  prevImageWrapperPosition: null,
  prevEditorState: null,
  maxSlideValue: 0,
  isTouchZoom: false,
  startImageWrapperScale: null,
  editLineItemMode: defaultEditLineItemMode,
};

const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    setIsEditMode: (state, { payload }: PayloadAction<boolean>) => {
      state.isEditMode = payload;
    },
    setPrevImageWrapperScale: (
      state,
      { payload }: PayloadAction<ICoordinates>,
    ) => {
      state.prevImageWrapperScale = payload;
    },
    setPrevImageWrapperPosition: (
      state,
      { payload }: PayloadAction<ICoordinates>,
    ) => {
      state.prevImageWrapperPosition = payload;
    },
    setVisualRotation: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.visualRotation = payload;
    },
    setCroppedImageDpi: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.croppedImageDpi = payload;
    },
    setOriginalCroppedImageSize: (
      state,
      { payload }: PayloadAction<ISize | null>,
    ) => {
      state.originalCroppedImageSize = payload;
    },
    setIsResetCroppedElement: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isResetCroppedElement = payload;
    },
    setCropObject: (
      state,
      { payload }: PayloadAction<ICropObject | null>,
    ) => {
      state.cropObject = payload;
    },
    setPrevEditorState: (
      state,
      { payload }: PayloadAction<IPrevEditorState | null>,
    ) => {
      state.prevEditorState = payload;
    },
    setMaxSlideValue: (state, { payload }: PayloadAction<number>) => {
      state.maxSlideValue = payload;
    },
    setStartImageWrapperScale: (
      state,
      { payload }: PayloadAction<ICoordinates>,
    ) => {
      state.startImageWrapperScale = payload;
    },
    setIsTouchZoom: (state, { payload }: PayloadAction<boolean>) => {
      state.isTouchZoom = payload;
    },
    resetEditorState: () => initialState,
    setIsResetCustomCorners: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isResetCustomCorners = payload;
    },
    setLineItemId: (
      state,
      { payload }: PayloadAction<number | null>,
    ) => {
      state.editLineItemMode.lineItemId = payload;
    },
    setIsEditLineItemMode: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.editLineItemMode.isEditLineItemMode = payload;
    },
    setIsSetupEditorView: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.editLineItemMode.isSetupEditorView = payload;
    },
    clearEditLineItemMode: (state) => {
      state.editLineItemMode = defaultEditLineItemMode;
    },
  },
});

export const {
  setIsEditMode,
  setCropObject,
  setCroppedImageDpi,
  setIsEditLineItemMode,
  setIsResetCroppedElement,
  setIsResetCustomCorners,
  setIsSetupEditorView,
  setIsTouchZoom,
  setLineItemId,
  setMaxSlideValue,
  setOriginalCroppedImageSize,
  setPrevEditorState,
  setPrevImageWrapperPosition,
  setPrevImageWrapperScale,
  setStartImageWrapperScale,
  setVisualRotation,
  resetEditorState,
} = editorSlice.actions;

export default editorSlice.reducer;
