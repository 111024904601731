import { FC } from 'react';

import useSelectProductOptions from 'hooks/sidePanel/useSelectProductOptions';
import useAppSelector from 'hooks/redux/useAppSelector';

import { activeImageObjectSelector } from 'redux/gallery';
import { optionGroupsSelector } from 'redux/sidePanel';

import productOptionService from 'utils/sidePanel/ProductOptionService';
import { SIZE } from 'constants/sidePanel/general';
import { IProductOption } from 'types/general';

import SidebarSettings from '../SidebarSettings';
import SizeCards from '../SizeCards';
import Card from '../Cards/Card';

interface IProps {
  optionsSteps: string[];
}

const SidebarBodyMobile: FC<IProps> = ({ optionsSteps }) => {
  const activeImageObject = useAppSelector(activeImageObjectSelector);
  const optionGroups = useAppSelector(optionGroupsSelector);

  const selectProductOption = useSelectProductOptions();

  const selectProductOptionHandler = (
    productOption: IProductOption,
  ) => {
    if (!activeImageObject) return;

    selectProductOption(productOption);
  };

  const optionGroup = productOptionService.getOptionGroupByType(
    optionGroups,
    optionsSteps[optionsSteps.length - 1],
  );

  const isSizeOptionGroup =
    optionGroup?.productOptionGroupType === SIZE;

  const OptionsList = optionGroup?.productOptions.map(
    (productOption) => (
      <Card
        disable={!activeImageObject}
        animations={[]}
        isClicked={false}
        setIsClicked={() => {}}
        setCardId={() => {}}
        key={productOption.productOptionId}
        productOption={productOption}
        selectProductOptionHandler={selectProductOptionHandler}
      />
    ),
  );

  return (
    <div className="sidebar-body">
      {optionGroup &&
        (isSizeOptionGroup ? (
          <SizeCards />
        ) : (
          <SidebarSettings
            disable={!activeImageObject}
            selectedOption={null}
            title={optionGroup.displayName}
            showSidebarSettingsHandler={() => {}}
          >
            <div className="product-grid">{OptionsList}</div>
          </SidebarSettings>
        ))}
    </div>
  );
};

export default SidebarBodyMobile;
