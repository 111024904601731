import { FileWithPath } from 'react-dropzone';

import { IImageObject, INewStorage } from 'types/gallery';
import axios from 'axios/config';
import urls from 'api/urls';

class GalleryService {
  async getNewStorageUrl(
    sessionId: string,
    fileName: string,
  ): Promise<INewStorage | void> {
    try {
      const { data } = await axios.get<INewStorage>(
        urls.images.getNewStorageUrl,
        {
          params: {
            SessionId: sessionId,
            OriginalFileName: fileName,
          },
        },
      );

      return data;
    } catch (error) {
      return console.log(error);
    }
  }

  async uploadFileToUrl(
    url: string,
    file: FileWithPath,
  ): Promise<void> {
    try {
      await axios.put(url, file, {
        headers: {
          'x-ms-blob-type': 'BlockBlob',
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  async getImage(
    sessionId: string,
    imageGuid: string,
  ): Promise<IImageObject | void> {
    try {
      const { data } = await axios.get<IImageObject>(
        urls.images.getImage,
        {
          params: {
            SessionId: sessionId,
            ImageGuid: imageGuid,
          },
        },
      );

      return data;
    } catch (error) {
      return console.log(error);
    }
  }

  async getImages(sessionId: string) {
    try {
      const { data } = await axios.get<IImageObject[]>(
        urls.images.getImages,
        {
          params: {
            SessionId: sessionId,
          },
        },
      );

      return data;
    } catch (error) {
      return console.log(error);
    }
  }

  async deleteImages(sessionId: string) {
    try {
      await axios.delete(urls.images.deleteImages, {
        params: {
          SessionId: sessionId,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  async deleteImage(sessionId: string, imageGuid: string) {
    try {
      await axios.delete(urls.images.deleteImage, {
        params: {
          SessionId: sessionId,
          ImageGuid: imageGuid,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }
}

export default new GalleryService();
