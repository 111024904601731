import { useHistory } from 'react-router-dom';
import { FC, useEffect } from 'react';

import useDefineDeviceByWindowSize from 'hooks/useDefineDeviceByWindowSize';
import useAppDispatch from 'hooks/redux/useAppDispatch';
import useAppSelector from 'hooks/redux/useAppSelector';

import MainContent from 'pages/Editor/components/MainContent';
import Footer from 'pages/Editor/components/Footer';

import EditorWindowContextProvider from 'context/providers/general/EditorWindowContextProvider';
import SidebarPopupContextProvider from 'context/providers/general/SidebarPopupContextProvider';
import ConfirmPopupContextProvider from 'context/providers/general/ConfirmPopupContextProvider';
import EditorCoreContextProvider from 'context/providers/canvas/EditorCoreContextProvider';
import SettingContextProvider from 'context/providers/general/SettingContextProvider';

import { setIsCartContainer } from 'redux/cart/cartReducer';
import { sessionIdSelector } from 'redux/auth';
import {
  getProductAsync,
  productCategoriesStatusSelector,
} from 'redux/sidePanel';

import productCategoriesDependOnRoutes from 'constants/sidePanel/productCategoriesDependOnRoutes';
import SidePanelRouter from 'routes/SidePanelRouter';

import Menu from './components/Menu';

const Editor: FC = () => {
  const sessionId = useAppSelector(sessionIdSelector);
  const productCategoriesStatus = useAppSelector(
    productCategoriesStatusSelector,
  );

  const dispatch = useAppDispatch();
  const { location } = useHistory();

  const { isDesktop } = useDefineDeviceByWindowSize();

  useEffect(() => {
    const productCategoryTypeParam =
      productCategoriesDependOnRoutes[location.pathname];

    const isRouteExist = productCategoriesStatus.some(
      ({ isActive, productCategoryType }) =>
        productCategoryType === productCategoryTypeParam && isActive,
    );

    if (!isRouteExist) return;

    dispatch(getProductAsync({ productCategoryTypeParam }));
  }, [
    dispatch,
    sessionId,
    location.pathname,
    productCategoriesStatus,
  ]);

  // set helper for styles
  useEffect(() => {
    dispatch(setIsCartContainer(false));
  }, [dispatch]);

  return (
    <SidebarPopupContextProvider>
      <SettingContextProvider>
        <ConfirmPopupContextProvider>
          <EditorCoreContextProvider>
            <EditorWindowContextProvider>
              {!isDesktop && <Menu />}
              <div className="main-container-col col">
                <MainContent />
                {isDesktop && <Footer />}
              </div>
              <SidePanelRouter />
            </EditorWindowContextProvider>
          </EditorCoreContextProvider>
        </ConfirmPopupContextProvider>
      </SettingContextProvider>
    </SidebarPopupContextProvider>
  );
};

export default Editor;
