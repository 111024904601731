import Sidebar from 'pages/Editor/components/Sidebar';

import { SIDE_PANEL_ROUTES } from './routes';

const sidePanelRouteList = [
  {
    path: SIDE_PANEL_ROUTES.ACRYLIC_PRINT,
    component: Sidebar,
  },
  {
    path: SIDE_PANEL_ROUTES.METAL_PRINT,
    component: Sidebar,
  },
  {
    path: SIDE_PANEL_ROUTES.CANVAS_WRAP,
    component: Sidebar,
  },
];

export default sidePanelRouteList;
