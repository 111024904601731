import { lineItemsSelector } from 'redux/cart';
import { createSelector } from 'reselect';

import { State } from '../types';
import { IEditorState } from './types';

export const editorSelector = ({ editor }: State): IEditorState =>
  editor;

export const isEditModeSelector = createSelector(
  editorSelector,
  ({ isEditMode }) => isEditMode,
);

export const prevImageWrapperScaleSelector = createSelector(
  editorSelector,
  ({ prevImageWrapperScale }) => prevImageWrapperScale,
);

export const prevImageWrapperPositionSelector = createSelector(
  editorSelector,
  ({ prevImageWrapperPosition }) => prevImageWrapperPosition,
);

export const visualRotationSelector = createSelector(
  editorSelector,
  ({ visualRotation }) => visualRotation,
);

export const originalCroppedImageSizeSelector = createSelector(
  editorSelector,
  ({ originalCroppedImageSize }) => originalCroppedImageSize,
);

export const croppedImageDpiSelector = createSelector(
  editorSelector,
  ({ croppedImageDpi }) => croppedImageDpi,
);

export const cropObjectSelector = createSelector(
  editorSelector,
  ({ cropObject }) => cropObject,
);

export const isResetCroppedElementSelector = createSelector(
  editorSelector,
  ({ isResetCroppedElement }) => isResetCroppedElement,
);

export const isResetCustomCornersSelector = createSelector(
  editorSelector,
  ({ isResetCustomCorners }) => isResetCustomCorners,
);

export const prevEditorStateSelector = createSelector(
  editorSelector,
  ({ prevEditorState }) => prevEditorState,
);

export const maxSlideValueSelector = createSelector(
  editorSelector,
  ({ maxSlideValue }) => maxSlideValue,
);

export const startImageWrapperScaleSelector = createSelector(
  editorSelector,
  ({ startImageWrapperScale }) => startImageWrapperScale,
);

export const isTouchZoomSelector = createSelector(
  editorSelector,
  ({ isTouchZoom }) => isTouchZoom,
);

// selector related to edit line item mode

export const editLineItemModeSelector = createSelector(
  editorSelector,
  ({ editLineItemMode }) => editLineItemMode,
);

export const lineItemIdSelector = createSelector(
  editLineItemModeSelector,
  ({ lineItemId }) => lineItemId,
);

export const isSetupEditorViewSelector = createSelector(
  editLineItemModeSelector,
  ({ isSetupEditorView }) => isSetupEditorView,
);

export const isEditLineItemModeSelector = createSelector(
  editLineItemModeSelector,
  ({ isEditLineItemMode }) => isEditLineItemMode,
);

export const lineItemSelector = createSelector(
  editLineItemModeSelector,
  lineItemsSelector,
  ({ lineItemId }, lineItems) =>
    lineItems.find(
      (lineItem) => lineItem.lineItemId === lineItemId,
    ) || null,
);
