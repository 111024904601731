import { FC, useCallback, useContext, useEffect, useState } from "react";

import useImageWrapperZoom from 'hooks/editor/zoom/useImageWrapperZoom';
import useCroppedImageDpi from 'hooks/editor/view/useCroppedImageDpi';
import useCreateCropObject from 'hooks/editor/useCreateCropObject';
import useEditorRefs from 'hooks/context/editor/useEditorRefs';
import useProductSize from 'hooks/sidePanel/useProductSize';
import useRotation from 'hooks/editor/rotation/useRotation';
import useResetEditor from 'hooks/editor/useResetEditor';
import useAppSelector from 'hooks/redux/useAppSelector';
import useAppDispatch from 'hooks/redux/useAppDispatch';
import useEditMode from 'hooks/editor/useEditMode';
import rotateService from 'utils/editor/rotate/RotateService';


import { TicksSlider } from 'components/TicksSlider';
import { RangeSlider } from 'components/RangeSlider';
import Icon from 'components/Icons/Icon';

import {
  setCropObject,
  setCroppedImageDpi,
  setIsResetCroppedElement,
  setIsResetCustomCorners,
  setMaxSlideValue,
  setStartImageWrapperScale,
} from 'redux/editor/editorReducer';
import {
  getProductAsync,
  selectedProductSizeOptionSelector,
} from 'redux/sidePanel';
import {
  maxSlideValueSelector,
  startImageWrapperScaleSelector,
} from 'redux/editor';

import { DPI_LIMIT, lessDpiMessage } from 'constants/editor/general';
import {
  ConfirmPopupContext,
  EditorWindowContext,
} from 'context/contexts/editor/general';

import Canvas from '../Canvas';
import Button from '../../../../components/Buttons/Button';
import useEditorState from '../../../../hooks/editor/useEditorState';
import FooterControlIcon from '../../../../components/Icons/FooterControlIcon';
import { CropStartContext } from "../../../../context/contexts/editor/canvas";
import usePrevValues from "../../../../hooks/editor/intersections/usePrevValues";

export const EditModal: FC = () => {
  const [isTickSlider, setIsTickSlider] = useState(true);
  const [prevRotation, setPrevRotation] = useState(0);

  const setConfirmPopupState = useContext(ConfirmPopupContext);
  const { showEditorWindowHandler } = useContext(EditorWindowContext);
  const { imageWrapperRef } = useEditorRefs();
  const [, undoEditorChanges] = useEditorState();

  const maxSlideValue = useAppSelector(maxSlideValueSelector);
  const startImageWrapperScale = useAppSelector(
    startImageWrapperScaleSelector,
  );
  const selectedProductSize = useAppSelector(
    selectedProductSizeOptionSelector,
  );

  const dispatch = useAppDispatch();

  const [dpi, calcDpi] = useCroppedImageDpi();
  const createCropObject = useCreateCropObject();
  const [rotation, setRotation] = useRotation();
  const [isEditMode, setIsEditMode] = useEditMode();
  const resetEditor = useResetEditor();
  const applyProductSize = useProductSize();
  const [saveEditorState] = useEditorState();


  const [zoomInImageWrapper, zoomOutImageWrapper] =
    useImageWrapperZoom();

  const isDpiLessLimit = dpi < DPI_LIMIT;

  const slideChangeHandler = (value: number | number[]) => {
    const imageWrapper = imageWrapperRef.current;

    if (!imageWrapper || !startImageWrapperScale || isDpiLessLimit)
      return null;

    const currentScale = imageWrapper.scale();

    if (!currentScale) return null;

    const neededScale =
      (maxSlideValue / -+value) * startImageWrapperScale.x;

    const scaleValue = Math.abs(currentScale.x - neededScale);

    if (neededScale > currentScale.x) {
      return zoomInImageWrapper(scaleValue);
    }

    return zoomOutImageWrapper(scaleValue);
  };

  const counterclockwiseRotateHandler = () => {
    const convertedRotation =
      rotateService.convertRotationConception(90);

    setRotation(convertedRotation);
  };

  const min = isDpiLessLimit ? 0 : -maxSlideValue;
  const max = isDpiLessLimit ? 1 : -DPI_LIMIT;
  const value = isDpiLessLimit ? 1 : -dpi;

  const saveHandler = () => {
    if (dpi < DPI_LIMIT && dpi) {
      return setConfirmPopupState({
        confirmHandler: () => {},
        text: lessDpiMessage,
      });
    }

    dispatch(setIsResetCroppedElement(true));
    dispatch(setIsResetCustomCorners(true));
    createCropObject();
    saveEditorState();
    setIsEditMode(!isEditMode);
    return showEditorWindowHandler(false);
  };

  const closeWindowHandler = () => {
    dispatch(setIsResetCroppedElement(true));
    dispatch(setIsResetCustomCorners(true));
    setIsEditMode(false);
    showEditorWindowHandler(false);
    dispatch(setCropObject(null));
  };

  const resetHandler = () => {
    dispatch(setIsResetCroppedElement(true));
    dispatch(setIsResetCustomCorners(true));
    setIsEditMode(false);
    dispatch(getProductAsync({}));
    closeWindowHandler();
    dispatch(setCropObject(null));
  };

  useEffect(() => {
    if (!selectedProductSize) return;

    const { size } = selectedProductSize;

    if (!size) return;

    resetEditor();

    if (!isEditMode) {
      setIsEditMode(true);
    }

    applyProductSize(size);
  }, [
    applyProductSize,
    isEditMode,
    resetEditor,
    selectedProductSize,
    setIsEditMode,
  ]);

  const handleSmoothRotation = useCallback((degrees: number) => {
    if (degrees < rotation || degrees < 0 || degrees > 0) {
      setPrevRotation(rotation);
      const newRotationDegreePositive = -(rotation - degrees);
      setRotation(newRotationDegreePositive);
    }
  }, [setRotation, rotation])

  return (
    <div className="e-modal">
      <ul className="e-modal-header">
        <li className="e-modal-item">
          <button
            className="e-modal-btn"
            onClick={closeWindowHandler}
          >
            <Icon className="e-modal-icon icon-e-times" />
          </button>
        </li>
        <li className="e-modal-item">
          <Button
            className="icon-undo-container"
            clickHandler={undoEditorChanges}
          >
            <Icon className="e-modal-icon icon-undo" />
          </Button>
        </li>
      </ul>
      <div style={{ width: '100%', height: '100%' }}>
        <span
          className="e-modal-dpi"
          style={{ top: '77px', right: '10px' }}
        >
          {dpi} DPI
        </span>
        <Canvas />
      </div>
      {isTickSlider ? (
        <TicksSlider
          min={-30}
          max={30}
          step={1}
          value={rotation}
          onChange={handleSmoothRotation}
        />
      ) : (
        <RangeSlider
          min={min}
          max={max}
          value={value}
          onChange={slideChangeHandler}
        />
      )}
      <ul className="e-modal-footer">
        <li className="e-modal-item">
          <button
            className="e-modal-btn danger"
            onClick={resetHandler}
          >
            reset
          </button>
        </li>
        <li className="e-modal-item e-modal-controls">
          <button
            className="e-modal-btn"
            disabled={isTickSlider}
            onClick={() => setIsTickSlider(true)}
          >
            <Icon className="e-modal-icon icon-angle-rotate" />
          </button>
          <span
            className="footer-controls-angle"
            onClick={counterclockwiseRotateHandler}
          >
            <Icon className="e-modal-icon icon-rotate" />
          </span>
          <button
            className="e-modal-btn"
            disabled={!isTickSlider}
            onClick={() => setIsTickSlider(false)}
          >
            <Icon className="e-modal-icon icon-zoom-bar" />
          </button>
        </li>
        <li className="e-modal-item">
          <button
            className="e-modal-btn medium"
            onClick={saveHandler}
          >
            save
          </button>
        </li>
      </ul>
    </div>
  );
};
