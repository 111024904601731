/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ICartState, ICart } from './types';

const defaultCartState = {
  cartId: null,
  sessionId: null,
  cartStatus: null,
  cartTotals: null,
  lineItems: [],
  shippingAddress: null,
  orderLevelOptions: null,
}

const initialState: ICartState = {
  cart: defaultCartState,
  isCartContainer: false,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCart: (state, { payload }: PayloadAction<ICart>) => {
      state.cart = payload;
    },
    setIsCartContainer: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isCartContainer = payload;
    },
    deleteCart: (state) => {
      state.cart = defaultCartState;
      console.log({defaultCartState});
    }
  },
});

export const { setCart, setIsCartContainer, deleteCart } = cartSlice.actions;

export default cartSlice.reducer;
