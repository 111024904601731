import sessionStorage from 'redux-persist/lib/storage/session';
import { persistReducer } from 'redux-persist';
import { combineReducers } from '@reduxjs/toolkit';

import editorReducer from './editor';
import sidePanelReducer from './sidePanel';
import galleryReducer from './gallery';
import cartReducer from './cart';
import authReducer from './auth';
import themeReducer from './theme/themeReducer';
import colorPickerReducer from './editor/colorPicker/colorPickerSlice';

const authConfig = {
  key: 'auth',
  storage: sessionStorage,
};

const themeConfig = {
  key: 'theme',
  storage: sessionStorage,
};

const rootReducer = combineReducers({
  editor: editorReducer,
  colorPicker: colorPickerReducer,
  sidePanel: sidePanelReducer,
  gallery: galleryReducer,
  cart: cartReducer,
  auth: persistReducer(authConfig, authReducer),
  theme: persistReducer(themeConfig, themeReducer),
});

export default rootReducer;
