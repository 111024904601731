import { useCallback } from 'react';

import useAppDispatch from 'hooks/redux/useAppDispatch';
import useAppSelector from 'hooks/redux/useAppSelector';

import {
  getProductAsync,
  productQuantitySelector,
  selectedProductOptionsSelector,
} from 'redux/sidePanel';

import { IProductOption, ISelectedOption } from 'types/general';
import { colorSelector } from 'redux/editor/colorPicker';

type SelectProductOptionHandler = (
  productOption: IProductOption,
) => void;

const useSelectProductOptions = (): SelectProductOptionHandler => {
  const quantity = useAppSelector(productQuantitySelector);
  const color = useAppSelector(colorSelector);
  const selectedOptions = useAppSelector(
    selectedProductOptionsSelector,
  );

  const dispatch = useAppDispatch();

  const selectProductOption = useCallback(
    (productOption: IProductOption) => {
      const { productOptionId, productOptionType } = productOption;

      const updatedSelectedOptions = selectedOptions.filter(
        ({ productOptionGroupId }) =>
          productOptionGroupId !== productOptionType,
      );

      const newProductOptions: ISelectedOption[] = [
        {
          productOptionId,
          productOptionGroupId: productOptionType,
        },
      ];

      if (
        productOptionType === 'Pages' &&
        productOptionId === 'COLOR'
      ) {
        newProductOptions.push({
          productOptionId: 'COLOR',
          productOptionGroupId: 'EdgeColor',
          productOptionDynamicValue: color,
        });
      }

      const uniqSelectedOptions = [
        ...updatedSelectedOptions,
        ...newProductOptions,
      ];

      const finalQuantity = !quantity ? 1 : quantity;

      const requestBodyParam = {
        quantity: finalQuantity,
        selectedOptions: uniqSelectedOptions,
      };

      dispatch(getProductAsync({ requestBodyParam }));
    },
    [color, quantity, dispatch, selectedOptions],
  );

  return selectProductOption;
};

export default useSelectProductOptions;
