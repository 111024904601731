import { createSelector } from 'reselect';

import productOptionService from 'utils/sidePanel/ProductOptionService';

import { ORIENTATION, SIZE } from 'constants/sidePanel/general';
import OPTION_STATES from 'constants/sidePanel/optionStates';
import { IProductOption } from 'types/general';

import convertInchesToMeters from 'utils/sidePanel/convertInchesToMeters';
import convertInchesToPixels from 'utils/sidePanel/convertMetersToPixels';
import {
  colorOptionCategory,
  edgeOptionsId,
  renderOptionCategory,
} from 'constants/editor/canvasWraps';
import { ISidePanelState } from './types';
import { State } from '../types';

// pure state selectors

export const sidePanelSelector = (state: State): ISidePanelState =>
  state.sidePanel;

export const showOptionsSelector = createSelector(
  sidePanelSelector,
  ({ showOptions }) => showOptions,
);

export const productSelector = createSelector(
  sidePanelSelector,
  ({ product }) => product,
);

export const quantityRulesSelector = createSelector(
  productSelector,
  ({ quantityRules }) => quantityRules,
);

export const optionGroupsSelector = createSelector(
  productSelector,
  ({ optionGroups }) => optionGroups,
);

export const productCategorySelector = createSelector(
  productSelector,
  ({ productCategory }) => productCategory,
);

export const productQuantitySelector = createSelector(
  productSelector,
  ({ quantity }) => quantity,
);

export const canAddProductToCartSelector = createSelector(
  productSelector,
  ({ canAddProductToCart }) => canAddProductToCart,
);

export const pricingAsConfiguredSelector = createSelector(
  productSelector,
  ({ pricingAsConfigured }) => pricingAsConfigured,
);

export const filtersUsedSelector = createSelector(
  optionGroupsSelector,
  (optionGroups) => {
    const sizeOptionGroup = optionGroups.find(
      (optionGroup) => optionGroup.productOptionGroupType === SIZE,
    );

    return sizeOptionGroup ? sizeOptionGroup.filtersUsed : [];
  },
);

export const productCategoriesStatusSelector = createSelector(
  sidePanelSelector,
  ({ productCategoriesStatus }) => productCategoriesStatus,
);

// converted state selectors

export const edgeOptionsSelector = createSelector(
  optionGroupsSelector,
  (optionGroups) =>
    optionGroups.find(
      ({ productOptionGroupType }) =>
        productOptionGroupType === renderOptionCategory,
    ) || null,
);

export const selectedEdgeSelector = createSelector(
  edgeOptionsSelector,
  (optionGroups) =>
    optionGroups?.productOptions.find(({ optionsStates }) =>
      productOptionService.getOptionStateValue(
        optionsStates,
        OPTION_STATES.IS_SELECTED,
      ),
    ) || null,
);

export const isColorEdgeSelector = createSelector(
  selectedEdgeSelector,
  (selectedEdge) => {
    const [, COLOR] = edgeOptionsId;

    return selectedEdge?.productOptionId === COLOR;
  },
);

export const edgeColorSelector = createSelector(
  optionGroupsSelector,
  (optionGroups) =>
    optionGroups.find(
      ({ productOptionGroupType }) =>
        productOptionGroupType === colorOptionCategory,
    ) || null,
);

export const selectedProductOptionsSelector = createSelector(
  productSelector,
  ({ optionGroups }) =>
    productOptionService.getSelectedProductOptions(optionGroups),
);

export const selectedProductSizeOptionSelector = createSelector(
  productSelector,
  ({ optionGroups }) =>
    productOptionService.getSelectedProductOptionByType(
      optionGroups,
      SIZE,
    ),
);

export const orientationProductOptionsSelector = createSelector(
  optionGroupsSelector,
  (optionGroups): IProductOption[] =>
    productOptionService.getOptionGroupByType(
      optionGroups,
      ORIENTATION,
    )?.productOptions || [],
);

export const productSizeOptionsSelector = createSelector(
  optionGroupsSelector,
  (optionGroups): IProductOption[] =>
    productOptionService.getOptionGroupByType(optionGroups, SIZE)
      ?.productOptions || [],
);

export const productCategoryTypeSelector = createSelector(
  productCategorySelector,
  (productCategory) => productCategory?.productCategoryType,
);

export const selectedProductOrientationSelector = createSelector(
  orientationProductOptionsSelector,
  (productOptions) =>
    productOptions.find(({ optionsStates }) =>
      productOptionService.getOptionStateValue(
        optionsStates,
        OPTION_STATES.IS_SELECTED,
      ),
    ),
);

export const trimPixelValuesSelector = createSelector(
  selectedProductSizeOptionSelector,
  (selectedSize) => {
    const size = selectedSize?.size;

    const trim = size?.trim;

    const topTrimInches = trim?.top || 0;
    const rightTrimInches = trim?.right || 0;
    const bottomTrimInches = trim?.bottom || 0;
    const leftTrimInches = trim?.left || 0;

    const [
      topTrimMeters,
      rightTrimMeters,
      bottomTrimMeters,
      leftTrimMeters,
    ] = convertInchesToMeters(
      topTrimInches,
      rightTrimInches,
      bottomTrimInches,
      leftTrimInches,
    );

    return convertInchesToPixels(
      topTrimMeters,
      rightTrimMeters,
      bottomTrimMeters,
      leftTrimMeters,
    );
  },
);

export const safetyPixelValuesSelector = createSelector(
  selectedProductSizeOptionSelector,
  (selectedSize) => {
    const size = selectedSize?.size;

    const trim = size?.trim;
    const safety = size?.safety;

    const topTrimInches = trim?.top || 0;
    const rightTrimInches = trim?.right || 0;
    const bottomTrimInches = trim?.bottom || 0;
    const leftTrimInches = trim?.left || 0;

    const topSafetyInches = safety?.top || 0;
    const rightSafetyInches = safety?.right || 0;
    const bottomSafetyInches = safety?.bottom || 0;
    const leftSafetyInches = safety?.left || 0;

    const topCtxWrapperInch = topSafetyInches - topTrimInches;
    const rightCtxWrapperInch = rightSafetyInches - rightTrimInches;
    const bottomCtxWrapperInch =
      bottomSafetyInches - bottomTrimInches;
    const leftCtxWrapperInch = leftSafetyInches - leftTrimInches;

    const [
      topCtxWrapperMeters,
      rightCtxWrapperMeters,
      bottomCtxWrapperMeters,
      leftCtxWrapperMeters,
    ] = convertInchesToMeters(
      topCtxWrapperInch,
      rightCtxWrapperInch,
      bottomCtxWrapperInch,
      leftCtxWrapperInch,
    );

    return convertInchesToPixels(
      topCtxWrapperMeters,
      rightCtxWrapperMeters,
      bottomCtxWrapperMeters,
      leftCtxWrapperMeters,
    );
  },
);

export const edgeSizePixelsSelector = createSelector(
  selectedProductSizeOptionSelector,
  (selectedSize) => {
    const size = selectedSize?.size;

    const edgeSizeInchesStr = size?.edgeSize;
    const edgeSizeInches = edgeSizeInchesStr ? +edgeSizeInchesStr : 0;

    const [edgeSizeMeters] = convertInchesToMeters(edgeSizeInches);
    const [edgeSizePixels] = convertInchesToPixels(edgeSizeMeters);

    return edgeSizePixels;
  },
);

// boolean state selectors

export const isPendingSidePanelSelector = createSelector(
  sidePanelSelector,
  ({ isSidePanelPending }) => isSidePanelPending,
);

export const isSelectedOrientationSelector = createSelector(
  orientationProductOptionsSelector,
  (productOptions) =>
    productOptions.some(({ optionsStates }) =>
      productOptionService.getOptionStateValue(
        optionsStates,
        OPTION_STATES.IS_SELECTED,
      ),
    ),
);

export const isSelectedProductSizeOptionSelector = createSelector(
  productSizeOptionsSelector,
  (productOptions) =>
    productOptions.some(({ optionsStates }) =>
      productOptionService.getOptionStateValue(
        optionsStates,
        OPTION_STATES.IS_SELECTED,
      ),
    ),
);

export const isSquareSizeSelector = createSelector(
  selectedProductSizeOptionSelector,
  (productSize) => {
    if (!productSize) return false;

    const { size } = productSize;

    if (!size) return false;

    const { width, height } = size;

    return +width === +height;
  },
);
