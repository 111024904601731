import axios from 'axios/config';
import {
  IGetProductAsyncBody,
  IProduct,
  IProductCategoryStatus,
} from 'redux/sidePanel/types';
import urls from 'api/urls';

class SidePanelService {
  async getProduct(
    sessionId: string,
    productCategoryType: string,
    requestBody: IGetProductAsyncBody,
  ): Promise<IProduct | void> {
    try {
      const { data } = await axios.post<IProduct>(
        urls.products.getProduct,
        requestBody,
        {
          params: {
            ProductCategoryType: productCategoryType,
            SessionId: sessionId,
          },
        },
      );

      return data;
    } catch (error) {
      return console.log('error', error);
    }
  }

  async getProductCategoriesStatus(
    sessionId: string,
  ): Promise<IProductCategoryStatus[] | void> {
    try {
      const { data } = await axios.get<IProductCategoryStatus[]>(
        urls.products.getProductCategoriesStatus,
        {
          params: {
            SessionId: sessionId,
          },
        },
      );

      return data;
    } catch (error) {
      return console.log('error', error);
    }
  }
}

export default new SidePanelService();
