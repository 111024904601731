import { FC } from 'react';

const GalleryFooterMobile: FC = () => (
  <div className="gallery-footer">
    <button className="btn btn-secondary gallery-footer-btn">
      select all
    </button>
    <button className="btn btn-secondary gallery-footer-btn">
      delete
    </button>
  </div>
);

export default GalleryFooterMobile;
