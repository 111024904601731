import { Link, useHistory } from 'react-router-dom';
import { useCallback, FC } from 'react';

import { resetEditorState } from 'redux/editor/editorReducer';
import { removeActiveImageObject } from 'redux/gallery';
import { sessionIdSelector } from 'redux/auth';
import { getProductAsync } from 'redux/sidePanel';
import {
  isEmptyCartSelector,
  lineItemsLengthSelector,
} from 'redux/cart';

import useAppSelector from 'hooks/redux/useAppSelector';
import useAppDispatch from 'hooks/redux/useAppDispatch';

import Button from 'components/Buttons/Button';
import Icon from 'components/Icons/Icon';

import { ROUTES } from 'constants/routes/app/routes';
import { SECOND_FAZE } from 'constants/temp';

import studio from 'assets/img/studio.png';
import useDefineDeviceByWindowSize from 'hooks/useDefineDeviceByWindowSize';

const Header: FC = () => {
  const lineItemsLength = useAppSelector(lineItemsLengthSelector);
  const isEmptyCart = useAppSelector(isEmptyCartSelector);
  const sessionId = useAppSelector(sessionIdSelector);

  const history = useHistory();
  const dispatch = useAppDispatch();

  const { isMobile } = useDefineDeviceByWindowSize();

  const switchToCartHandler = useCallback(() => {
    const { pathname } = history.location;

    const isLocatedCart = pathname === ROUTES.CART;

    if (!sessionId || isLocatedCart) return;

    dispatch(getProductAsync({}));
    dispatch(removeActiveImageObject());
    dispatch(resetEditorState());

    history.push(ROUTES.CART);
  }, [sessionId, dispatch, history]);

  return (
    <header className="header">
      <a
        className="header-logo-container"
        href="https://www.hhcolorlab.com/"
        target="_blank"
        rel="noreferrer"
      >
        <Icon className="icon-logo header-logo" />
      </a>
      {isMobile && (
        <span
          className="header-user-number"
          style={{
            textAlign: 'center',
            marginLeft: 'auto',
            fontSize: 10,
          }}
        >
          Mobile coming soon. Please use tablet or desktop
        </span>
      )}
      <div className="header-user col-8 col-md col-lg-8">
        <Icon className="header-user-icon icon-person" />
        <span className="header-user-title">customer service</span>
        <span className="header-user-number">800-821-1305</span>
      </div>
      <div className="header-info col-3 col-md col-lg-3">
        {SECOND_FAZE && (
          <Button className="header-info-btn btn-default">
            <img
              src={studio}
              alt="studio"
              className="header-btn-icon btn-icon"
            />
            <span className="header-btn-text">Studio Name</span>
          </Button>
        )}
        <Button
          className="header-cart btn-main"
          clickHandler={switchToCartHandler}
        >
          <Icon className="header-cart-icon icon-cart" />
          {!isEmptyCart && (
            <span className="header-cart-count">
              {lineItemsLength}
            </span>
          )}
        </Button>
      </div>
    </header>
  );
};

export default Header;
