import { FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import CHECKOUT_ROUTES from 'constants/routes/checkout/routes';
import { ROUTES } from 'constants/routes/app/routes';

import useLoader from 'hooks/context/general/useLoader';
import useAppDispatch from 'hooks/redux/useAppDispatch';
import useAppSelector from 'hooks/redux/useAppSelector';

import { productCategoryTypeSelector } from 'redux/sidePanel';
import {setIsCreatedCart} from 'redux/auth/authReducer';
import {isEmptyCartSelector, lineItemsSelector} from 'redux/cart';
import {getSessionIdAsync, sessionIdSelector} from 'redux/auth';

import StepControls from 'components/StepControls';

import SuccessPopup from 'pages/Checkout/components/SuccessPopup';
import CartTable from 'pages/Cart/components/CartTable';

import toLowerCaseFirstChar from 'utils/string/toLowerCaseFirstChar';
import summaryService from 'api/checkout/SummaryService';
import { ISummary } from 'types/checkout/summary';

import CheckoutTitle from '../../components/CheckoutTitle';
import ConfirmHead from './components/ConfirmHead';
import TotalOrder from './components/TotalOrder';
import ShipTo from './components/ShipTo';
import {ILineItem} from "../../../../types/cart";

const Summary: FC = () => {
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const [summaryResponse, setSummaryResponse] =
    useState<ISummary | null>(null);

  const { handleShowLoader, handleHideLoader } = useLoader();

  const isEmptyCart = useAppSelector(isEmptyCartSelector);
  const sessionId = useAppSelector(sessionIdSelector);
  const productCategoryType = useAppSelector(
    productCategoryTypeSelector,
  );
  const lineItems = useAppSelector(lineItemsSelector);
  const lineItemIDs = lineItems.map((lineItem) => lineItem.lineItemId);

  const dispatch = useAppDispatch();
  const history = useHistory();

  const previousStepHandler = useCallback(() => {
    history.push(CHECKOUT_ROUTES.SHIPPING_ADDRESS);
  }, [history]);

  const hidePopupHandler = useCallback(
    () => setShowSuccessPopup(false),
    [],
  );

  const continueOrderingHandler = useCallback(() => {
    const nextRoute = `${ROUTES.EDITOR}/${toLowerCaseFirstChar(
      productCategoryType || '',
    )}`;

    history.push(nextRoute);
  }, [history, productCategoryType]);

  const submitOrder = useCallback(async () => {
    if (!sessionId || !summaryResponse?.cartId) return;

    handleShowLoader();

    await summaryService.checkoutCart(
      sessionId,
      summaryResponse.cartId,
    );

    handleHideLoader();

    setShowSuccessPopup(true);
    setSummaryResponse(null);

    dispatch(setIsCreatedCart(false));
    dispatch(getSessionIdAsync());
  }, [
    dispatch,
    sessionId,
    summaryResponse,
    handleShowLoader,
    handleHideLoader,
  ]);

  useEffect(() => {
    if (!sessionId) return;

    summaryService.getOrderSummary(sessionId).then((data) => {
      if (!data) return;

      setSummaryResponse(data);
    });
  }, [sessionId]);

  return (
    <>
      {showSuccessPopup && (
        <SuccessPopup
          hidePopupHandler={hidePopupHandler}
          continueOrderHandler={continueOrderingHandler}
        />
      )}
      <div className="check-body">
        <CheckoutTitle title="Summary" />
        <ConfirmHead
          studioName={summaryResponse?.studioName || ''}
          accountNumber={summaryResponse?.accountNumber}
        />
        <CartTable />
        <TotalOrder
          itemsCount={summaryResponse?.lineItems?.length || 0}
          total={summaryResponse?.total || 0}
          subTotal={summaryResponse?.subTotal || 0}
          rush={!!summaryResponse?.rush}
          rushTotal={summaryResponse?.rushTotal || ''}
        />
        <ShipTo shipping={summaryResponse?.shipping} />
      </div>
      <StepControls
        className="check-footer"
        previousStepContent="Back"
        nextStepHandler={
          isEmptyCart ? continueOrderingHandler : submitOrder
        }
        nextStepContent={
          isEmptyCart ? 'Continue Ordering' : 'Submit Order'
        }
        previousStepHandler={previousStepHandler}
      />
    </>
  );
};

export default Summary;
