import { createSelector } from 'reselect';

import { State } from 'redux/types';

const colorPickerStateSelector = (state: State) => state.colorPicker;

export const showColorPickerSelector = createSelector(
  colorPickerStateSelector,
  ({ showColorPicker }) => showColorPicker,
);

export const eyeDropperModeSelector = createSelector(
  colorPickerStateSelector,
  ({ eyeDropperMode }) => eyeDropperMode,
);

export const colorSelector = createSelector(
  colorPickerStateSelector,
  ({ color }) => color,
);
