import { FC, useCallback } from 'react';

import SelectedOptionsList from 'components/SelectedOptionsList';
import Icon from 'components/Icons/Icon';
import Counter from 'components/Counter';

import useAppDispatch from 'hooks/redux/useAppDispatch';
import useLoader from 'hooks/context/general/useLoader';
import useCropObject from 'hooks/editor/useCropObject';
import useQuantity from 'hooks/useQuantity';

import productOptionService from 'utils/sidePanel/ProductOptionService';
import IMAGE_SIZE_INDEXES from 'constants/gallery/imageSizeIndexes';
import { deleteLineItemAsync, updateLineItemAsync } from 'redux/cart';
import { ILineItem } from 'types/cart';

const OrderSummary: FC<ILineItem> = ({
  lineItemId,
  lineItemTotal,
  selectedOptions,
  quantity: startQuantity,
  productCategory,
  productId,
  productCategoryDisplayName,
  lineItemAppDataJson,
  sides,
}) => {
  const { handleHideLoader, handleShowLoader } = useLoader();

  const dispatch = useAppDispatch();

  const updateLineItem = useCallback(
    (value: number) => {
      const preparedOptions =
        productOptionService.prepareSelectedOptions(selectedOptions);

      const lineItem = {
        lineItemId,
        productCategory,
        quantity: value,
        productId,
        lineItemJson: {
          selectedOptions: preparedOptions,
          sides,
        },
        lineItemAppDataJson,
      };

      dispatch(updateLineItemAsync(lineItem));
    },
    [
      dispatch,
      lineItemId,
      lineItemAppDataJson,
      productCategory,
      productId,
      selectedOptions,
      sides,
    ],
  );

  const {
    quantity,
    incrementQuantity,
    decrementQuantity,
    changeQuantityHandler,
  } = useQuantity(startQuantity, updateLineItem);

  const cropImgUrl = useCropObject(
    sides || [],
    lineItemAppDataJson || [],
    IMAGE_SIZE_INDEXES.MEDIUM,
  );

  const deleteLineItemHandler = async () => {
    handleShowLoader();

    await dispatch(deleteLineItemAsync(lineItemId));

    handleHideLoader();
  };

  return (
    <div className="order">
      <button className="order-btn" onClick={deleteLineItemHandler}>
        <Icon className="order-icon icon-times" />
      </button>
      <img src={cropImgUrl} alt="CartImage" className="order-img" />
      <div>
        <h2 className="order-title">{productCategoryDisplayName}</h2>
        <SelectedOptionsList
          className="order-text"
          selectedOptions={selectedOptions}
        />
        <div className="order-counter">
          <div className="cart-sum">
            <Counter
              quantity={quantity}
              btnClassNames="cart-quantity-btn"
              inputClassNames="cart-quantity"
              incrementQuantity={incrementQuantity}
              decrementQuantity={decrementQuantity}
              changeQuantityHandler={changeQuantityHandler}
            />
          </div>
          <span className="order-sum">{`$ ${lineItemTotal}`}</span>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
