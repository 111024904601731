import { FC, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import useCroppedImageDpi from 'hooks/editor/view/useCroppedImageDpi';
import useAlertModal from 'hooks/context/general/useAlertModal';
import useExistLineItem from 'hooks/cart/useExistLineItem';
import useResetEditor from 'hooks/editor/useResetEditor';
import useAppSelector from 'hooks/redux/useAppSelector';
import useAppDispatch from 'hooks/redux/useAppDispatch';
import useEditMode from 'hooks/editor/useEditMode';
import useLineItem from 'hooks/editor/useLineItem';
import useQuantity from 'hooks/useQuantity';

import Button from 'components/Buttons/Button';
import Counter from 'components/Counter';

import { addLineItemAsync, updateLineItemAsync } from 'redux/cart';
import { resetEditorState } from 'redux/editor/editorReducer';
import { removeActiveImageObject } from 'redux/gallery';
import {
  getProductAsync,
  productQuantitySelector,
  pricingAsConfiguredSelector,
  canAddProductToCartSelector,
  selectedProductOptionsSelector,
  setShowOptions,
} from 'redux/sidePanel';
import {
  isEditLineItemModeSelector,
  lineItemIdSelector,
} from 'redux/editor';

import { DPI_LIMIT, lessDpiMessage } from 'constants/editor/general';
import { SidebarPopupContext } from 'context/contexts/editor/general';
import { ROUTES } from 'constants/routes/app/routes';

import { Event } from 'types/general';
import useDefineDeviceByWindowSize from '../../../../../../hooks/useDefineDeviceByWindowSize';

const SidebarFooter: FC = () => {
  const { showSidebarPopupHandler } = useContext(SidebarPopupContext);
  const handleShowModal = useAlertModal();

  const defaultQuantity = useAppSelector(productQuantitySelector);
  const lineItemId = useAppSelector(lineItemIdSelector);
  const selectedProductOptions = useAppSelector(
    selectedProductOptionsSelector,
  );
  const canAddProductToCart = useAppSelector(
    canAddProductToCartSelector,
  );
  const isEditLineItemMode = useAppSelector(
    isEditLineItemModeSelector,
  );
  const { formattedPrice } = useAppSelector(
    pricingAsConfiguredSelector,
  );

  const history = useHistory();
  const dispatch = useAppDispatch();

  const [, setIsEditMode] = useEditMode();
  const findExistLineItem = useExistLineItem();
  const createLineItem = useLineItem();
  const resetEditor = useResetEditor();
  const [dpi, calcDpi] = useCroppedImageDpi();

  const { isMobile } = useDefineDeviceByWindowSize();

  const updateProduct = useCallback(
    (value: number) => {
      const requestBodyParam = {
        selectedOptions: selectedProductOptions,
        quantity: value,
      };

      dispatch(getProductAsync({ requestBodyParam }));
    },
    [dispatch, selectedProductOptions],
  );

  const {
    quantity,
    incrementQuantity,
    decrementQuantity,
    changeQuantityHandler,
  } = useQuantity(defaultQuantity, updateProduct);

  const hardResetEditor = useCallback(() => {
    resetEditor();
    setIsEditMode(false);
    dispatch(getProductAsync({}));
    dispatch(setShowOptions(true));
    showSidebarPopupHandler(false);
  }, [dispatch, resetEditor, setIsEditMode, showSidebarPopupHandler]);

  const addToCartHandler = useCallback(
    (e: Event<HTMLButtonElement>) => {
      e.stopPropagation();

      const currentDpi = calcDpi();

      if (currentDpi < DPI_LIMIT) {
        return handleShowModal(lessDpiMessage);
      }
      if (!isMobile && currentDpi < DPI_LIMIT) {
        return handleShowModal(lessDpiMessage);
      }

      const lineItem = createLineItem(quantity);

      hardResetEditor();

      if (isEditLineItemMode) {
        dispatch(updateLineItemAsync({ lineItemId, ...lineItem }));

        dispatch(removeActiveImageObject());
        dispatch(resetEditorState());

        return history.push(ROUTES.CART);
      }

      const existLineItem = findExistLineItem(quantity);

      if (!existLineItem) {
        dispatch(addLineItemAsync(lineItem));
      } else {
        dispatch(updateLineItemAsync(existLineItem));
      }

      return showSidebarPopupHandler(true);
    },
    [
      createLineItem,
      dispatch,
      findExistLineItem,
      hardResetEditor,
      history,
      isEditLineItemMode,
      lineItemId,
      quantity,
      showSidebarPopupHandler,
      calcDpi,
      handleShowModal,
      isMobile,
    ],
  );

  const buttonText = isEditLineItemMode
    ? 'Update item'
    : 'Add to Cart';

  return (
    <footer className="sidebar-footer">
      <div className="sidebar-footer-total">
        <span className="sidebar-footer-title">Total: &nbsp;</span>
        <span className="sidebar-footer-price">
          {`$ ${formattedPrice}`}
        </span>
      </div>
      <div className="sidebar-footer-cart">
        <div className="sidebar-quantity">
          <Counter
            quantity={quantity}
            btnClassNames="sidebar-quantity-btn"
            inputClassNames="sidebar-quantity-input"
            disabled={!canAddProductToCart}
            incrementQuantity={incrementQuantity}
            decrementQuantity={decrementQuantity}
            changeQuantityHandler={changeQuantityHandler}
          />
        </div>
        <Button
          className="sidebar-cart-btn btn-main"
          isDisabled={!canAddProductToCart}
          clickHandler={addToCartHandler}
        >
          {buttonText}
        </Button>
      </div>
    </footer>
  );
};

export default SidebarFooter;
