import { FC, memo } from 'react';

import { IUiSelectedOption } from 'types/general';
import useDefineDeviceByWindowSize from "../../hooks/useDefineDeviceByWindowSize";

interface IProps {
  className: string;
  selectedOptions: IUiSelectedOption[];
}

const SelectedOptionsList: FC<IProps> = memo(
  ({ className, selectedOptions }: IProps) => {
    const { isMobile } = useDefineDeviceByWindowSize();
    return (
      <>
        {selectedOptions.map(
          ({
             productOptionGroupId,
             productOptionIdDisplayName,
             productOptionGroupIdDisplayName,
            optionTotal
           }) => (
            <span
              className={className}
              key={productOptionGroupId}
            >{isMobile ? `${productOptionGroupIdDisplayName}: ${productOptionIdDisplayName} ($${optionTotal})` : `${productOptionGroupIdDisplayName}: ${productOptionIdDisplayName}`}</span>
          ),
        )}
      </>
    )
  },
);

export default SelectedOptionsList;
