import { SIDE_PANEL_ROUTES } from 'constants/routes/app/routes';

import { productCategories } from './productCategories';

const [acrylicPrint, metalPrint, canvasWrap] = productCategories;
const { METAL_PRINT, ACRYLIC_PRINT, CANVAS_WRAP } = SIDE_PANEL_ROUTES;

const sidePanelRoutesDependOnCategory = {
  [metalPrint.productCategoryType]: METAL_PRINT,
  [acrylicPrint.productCategoryType]: ACRYLIC_PRINT,
  [canvasWrap.productCategoryType]: CANVAS_WRAP,
};

export default sidePanelRoutesDependOnCategory;
