import { useCallback } from 'react';

import useProductSize from 'hooks/sidePanel/useProductSize';
import useAppSelector from 'hooks/redux/useAppSelector';

import { basedOrientationIdSelector } from 'redux/gallery';
import { ISizeString } from 'types/general';

import useResetEditor from './useResetEditor';

type Result = (productOptionId: string, size: ISizeString) => void;

const useOrientation = (): Result => {
  const basedOrientationId = useAppSelector(
    basedOrientationIdSelector,
  );

  const applyProductSize = useProductSize();
  const resetEditor = useResetEditor();

  const changeOrientationHandler = useCallback(
    (productOptionId: string, size: ISizeString) => {
      resetEditor();

      const isVerticalBasedOrientation = basedOrientationId === 'V';
      const isVerticalNextOrientation = productOptionId === 'V';

      if (isVerticalBasedOrientation) {
        if (!isVerticalNextOrientation) {
          const newSize = {
            ...size,
            width: size.height,
            height: size.width,
          };

          return applyProductSize(newSize);
        }

        const newSize = {
          ...size,
          width: size.width,
          height: size.height,
        };

        return applyProductSize(newSize);
      }

      if (isVerticalNextOrientation) {
        const newSize = {
          ...size,
          width: size.height,
          height: size.width,
        };

        return applyProductSize(newSize);
      }

      const newSize = {
        ...size,
        width: size.width,
        height: size.height,
      };

      return applyProductSize(newSize);
    },
    [applyProductSize, resetEditor, basedOrientationId],
  );

  return changeOrientationHandler;
};

export default useOrientation;
