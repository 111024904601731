import { Image } from 'konva/lib/shapes/Image';
import { useEffect, useState } from 'react';
import Konva from 'konva';

import rotateService from 'utils/editor/rotate/RotateService';
import shapePointsService from 'utils/editor/coordinates/ShapePointsService';
import getImageUrlBySize from 'utils/gallery/getImageUrlBySize';
import cropService from 'utils/editor/crop/CropService';

import { ILineItemAppDataJson, ISides } from 'types/cart';
import { IImageObject } from 'types/gallery';

import useAppSelector from 'hooks/redux/useAppSelector';
import { imageObjectsSelector } from 'redux/gallery';

const useCropObject = (
  sides: ISides[],
  lineItemAppDataJson: ILineItemAppDataJson[],
  imageSize: number,
): string => {
  const [croppedImgUrl, setCroppedImgUrl] = useState('');
  const [rotatedImage, setRotatedImage] = useState('');

  const imageObjects = useAppSelector(imageObjectsSelector);

  useEffect(() => {
    if (!sides) return;

    const data = sides[0];

    if (!data) return;

    const { rotation, imageGuid } = data;

    const imageObject = imageObjects.find(
      (imageObjectParam: IImageObject) =>
        imageObjectParam.imageGuid === imageGuid,
    );

    if (!imageObject) return;

    const mediumImageUrl = getImageUrlBySize(imageObject, imageSize);

    Konva.Image.fromURL(mediumImageUrl, (img: Image) => {
      if (rotation) {
        const actualShapeSize = {
          width: img.width() * img.scaleX(),
          height: img.height() * img.scaleY(),
        };

        const freeShapePoint =
          shapePointsService.getFreeCenterPoint(actualShapeSize);

        const currentDegrees = img.rotation();

        const shiftRotatePoint = rotateService.getShiftRotationPoint(
          freeShapePoint,
          currentDegrees,
          rotation,
        );

        const nextPosition = {
          x: img.x() + shiftRotatePoint.x,
          y: img.y() + shiftRotatePoint.y,
        };

        img.rotation(rotation);
        img.position(nextPosition);
      }

      setRotatedImage(img.toDataURL({ quality: 1 }));
    });
  }, [imageObjects, imageSize, sides]);

  useEffect(() => {
    if (!lineItemAppDataJson) return;

    const crop = lineItemAppDataJson[0];

    Konva.Image.fromURL(rotatedImage, (img: Image) => {
      const { width, height } = img.getClientRect();

      const imageCrop = cropService.calcImageCrop(
        crop.value,
        width,
        height,
      );

      setCroppedImgUrl(
        img.toDataURL({
          quality: 1,
          x: imageCrop.x,
          y: imageCrop.y,
          width: imageCrop.width,
          height: imageCrop.height,
        }),
      );
    });
  }, [rotatedImage, lineItemAppDataJson, imageObjects, imageSize]);

  return croppedImgUrl;
};

export default useCropObject;
