import React from 'react';
import cn from 'classnames';

interface Props {
  url: string;
  displayName: string;
  isSelected: boolean;
  productCategoryType: string;
  selectProductCategoryHandler: (productCategoryType: string) => void;
}

const CategoryCard: React.FC<Props> = ({
  url,
  displayName,
  isSelected,
  productCategoryType,
  selectProductCategoryHandler,
}: Props) => (
  <div
    onClick={() => selectProductCategoryHandler(productCategoryType)}
    className={cn('product-card-container', {
      'is-active': isSelected,
    })}
  >
    <div className="product-card">
      <div
        className="product-card-img"
        style={{ backgroundImage: `url(${url})` }}
      />
      <i className="product-card-checked icon icon-checked" />
      <i className="product-card-info icon icon-info" />
    </div>
    <span className="product-card-title">{displayName}</span>
  </div>
);

export default CategoryCard;
