/* eslint-disable no-await-in-loop */
import galleryService from 'api/GalleryService';
import { IImageObject } from 'types/gallery';
import delay from 'utils/general/delay';

const getImageLoopAsync = async (
  sessionId: string,
  imageGuid: string,
): Promise<IImageObject> => {
  let isDelay = false;

  while (true) {
    if (isDelay) {
      await delay(0.5);
    }

    const imageObject = await galleryService.getImage(
      sessionId,
      imageGuid,
    );

    isDelay = true;

    if (imageObject?.isProcessed) {
      return imageObject;
    }
  }
};

export default getImageLoopAsync;
