import { useCallback } from 'react';

import useEditorRefs from 'hooks/context/editor/useEditorRefs';
import useAppSelector from 'hooks/redux/useAppSelector';

import { activeImageObjectSelector } from 'redux/gallery';
import { visualRotationSelector } from 'redux/editor';
import { colorSelector } from 'redux/editor/colorPicker';
import { sidePanelSelector } from 'redux/sidePanel';

import productOptionService from 'utils/sidePanel/ProductOptionService';

import useCreateCropObject from './useCreateCropObject';

type CreateLineItem = (quantity: number) => any | null;

const useLineItem = (): CreateLineItem => {
  const activeImageObject = useAppSelector(activeImageObjectSelector);
  const visualRotation = useAppSelector(visualRotationSelector);
  const { optionGroups, productCategory, productId } =
    useAppSelector(sidePanelSelector).product;
  const color = useAppSelector(colorSelector);

  const { imageWrapperRef, mainLayerRef, croppedImageRef } =
    useEditorRefs();

  const getCropObject = useCreateCropObject();

  const createLineItem = useCallback(
    (quantity: number) => {
      const imageWrapper = imageWrapperRef.current;
      const croppedImage = croppedImageRef.current;
      const mainLayer = mainLayerRef.current;

      if (
        !activeImageObject ||
        !productCategory ||
        !imageWrapper ||
        !mainLayer ||
        !croppedImage
      )
        return null;

      const cropObject = getCropObject();

      const { imageGuid } = activeImageObject;

      const selectedOptions =
        productOptionService.getSelectedProductOptions(optionGroups);

      const updatedOptions = selectedOptions.map((el) => {
        if (el.productOptionGroupId === 'EdgeColor') {
          return { ...el, productOptionDynamicValue: color };
        }
        return el;
      });

      const sideObject = {
        sideIndex: 1,
        imageGuid,
        rotation: visualRotation,
        crop: cropObject,
      };

      const lineItemJson = {
        selectedOptions: updatedOptions,
        sides: [sideObject],
      };

      const imageScaleJson = {
        key: 'imageScale',
        value: {
          x: imageWrapper.scaleX(),
          y: imageWrapper.scaleY(),
        },
      };

      const layerScaleJson = {
        key: 'layerScale',
        value: {
          x: mainLayer.scaleX(),
          y: mainLayer.scaleY(),
        },
      };

      const lineItem = {
        productId,
        productCategory: productCategory.productCategoryType,
        quantity,
        lineItemJson,
        lineItemAppDataJson: [imageScaleJson, layerScaleJson],
      };

      return lineItem;
    },
    [
      imageWrapperRef,
      activeImageObject,
      getCropObject,
      optionGroups,
      mainLayerRef,
      productCategory,
      croppedImageRef,
      productId,
      visualRotation,
      color,
    ],
  );

  return createLineItem;
};

export default useLineItem;
