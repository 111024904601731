export const canvasWrapsBorder = 3;
export const renderOptionCategory = 'Pages';
export const colorOptionCategory = 'EdgeColor';
export const edgeOptionsId = ['IMAGE', 'COLOR'];
export const colorWrapsId = [
  'topColorWrap',
  'rightColorWrap',
  'bottomColorWrap',
  'leftColorWrap',
];

export const presetsColorConfig = {
  format: 'hex',
  amount: 16,
};

export const mostCommonColorConfig = {
  format: 'hex',
  amount: 1,
};
